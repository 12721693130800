.error_content {
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	color: #3c3c3c;
}
.redirection {
	font-weight: 700;
	font-size: 14px;
	line-height: 25px;
	text-decoration-line: underline;
	color: #3c3c3c;
}
