@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
html,
body {
	scroll-behavior: smooth;
	font-family: var(--poppins) !important;
	height: 100vh;
	background-color: #f7f7f8;
	font-display: swap;
}
/* scroll  */
::-webkit-scrollbar {
	width: 8px;
}
#popup::-webkit-scrollbar {
	width: 19px;
	background-color: #f5f5f5;
}
::-webkit-scrollbar-track {
	background-color: #f7f7f8;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: rgba(84, 230, 212, 1);
}
.App {
	text-align: center;
	background: #f7f7f8;
	font-family: var(--poppins) !important;
	width: 100%;
	color: #3c3c3c;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

:root {
	--poppins: 'Poppins', sans-serif;
}
.ReactModal__Content {
	border: 10px solid rgb(255, 255, 255) !important;
	z-index: 9999 !important;
}
.ReactModal__Overlay {
	z-index: 9999 !important;
}

.Toastify__toast-container {
	z-index: 999999 !important;
}
